import request from './services/axios.js'

//获取刊物列表接口
export function GetPermissionList() {
  return request({
    url: '/vas/member/permissionList',
    method: 'get'
  })
}

//获取刊物领域设置信息
export function GetDomainType(params) {
  return request({
    params,
    url: '/vas/statistics/getDomainType',
    method: 'get'
  })
}

//获取分类刊物列表
export function GetSameCatMags(params) {
  return request({
    params,
    url: '/vas/statistics/getSameCatMags',
    method: 'get'
  })
}

//保存领域刊物设置
export function SaveDomainType(params) {
  return request({
    params,
    url: '/vas/statistics/saveDomainType',
    method: 'post'
  })
}

//获取自定义领域刊物列表
export function GetCustomDomainMags(params) {
  return request({
    params,
    url: '/vas/statistics/getCustomDomainMags',
    method: 'get'
  })
}
// 获取任务管理未处理数
export function GetDeploySize(params) {
  return request({
    params,
    url: '/vas/flow/deploySize',
    method: 'get'
  })
}
