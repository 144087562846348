import request from './services/axios.js'

export function Login(params) {
  return request({
    data: params,
    url: '/login',
    method: 'post'
  })
}

export function GetAesKey() {
  return request({
    url: '/aesKey',
    method: 'get'
  })
}

export function GetPublicKey() {
  return request({
    url: '/publicKey',
    method: 'get'
  })
}

export function GetRegister(params) {
  return request({
    params,
    url: '/public-center/api/isRegistered',
    method: 'get'
  })
}

export function Logout() {
  return request({
    url: '/logout',
    method: 'get'
  })
}
