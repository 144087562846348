// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from "vue";
import App from "./App";
import router from "./router";
import store from "./store";
// 引入ElementUI
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import { message } from "./assets/js/resetMessage";
Vue.use(ElementUI, { size: "small", zIndex: 3000 });
import "../public/css/reset.css";
import "./global";

//引入echarts

import echarts from "echarts";
import "echarts-wordcloud";
Vue.prototype.$echarts = echarts;
// 添加elementUI的message校验
Vue.prototype.$message = message;
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");

router.beforeEach((to, from, next) => {
  // console.log(to,'to');
  // console.log(from,'from');
  let userToken = sessionStorage.getItem("token");
  let role = sessionStorage.getItem("role");
  // console.log(role,'role');
  // console.log(to.meta.requireAuth,'to.meta.requireAuth');
  if (to.meta.requireAuth) {
    // 判断该路由是否需要登录权限
    // console.log(userToken,'userToken');
    if (userToken) {
      // 判断本地是否存在token
      if (to.meta.roles.length !== 0) {
        for (let i = 0; i < to.meta.roles.length; i++) {
          if (role == to.meta.roles[i]) {
            next();
            break;
          } else if (i == to.meta.roles.length - 1) {
            next({
              path: "/Error"
            });
          }
        }
      } else {
        next();
      }
    } else {
      next({
        path: "/"
      });
    }
  } else {
    next();
  }
  /* 如果本地存在token,则不允许直接跳转到登录页面 */
  if (to.fullPath === "/") {
    if (userToken) {
      next({
        path: from.fullPath
      });
    } else {
      next();
    }
  }
});
