<!-- header -->
<template>
  <div class="header" :class="isSalesHeader ? 'sales' : 'custom'"
    v-if="!['emailPreviewHtml','citationPushPreviewHtml'].includes($route.name)">
    <salesHeader v-if="isSalesHeader" />
    <customHeader v-else />
  </div>
</template>

<script>
import customHeader from './customHeader'
import salesHeader from './salesHeader'
export default {
  name: 'index',
  data () {
    return {}
  },
  props: {},
  components: { customHeader, salesHeader },
  computed: {
    // 0:普通  1:超级管理员 2:管理员  3:销售  4:设计
    isSales () {
      return sessionStorage.getItem('role') == 3
    },
    isDesigner () {
      return sessionStorage.getItem('role') == 4
    },
    isSalesHeader () {
      return this.isSales || this.isDesigner || this.$route.query.fromPage === 'pm' || ['/collection', '/add', '/edit', '/log', '/view', '/statistics', '/comparison'].includes(this.$route.path)
    }
  },
  created () { },
  methods: {}
}
</script>

<style lang="scss" scoped>
.custom {
  background-color: #2644ca;
}

.sales {
  background-color: #2644ca;
  height: 60px;
}
</style>
