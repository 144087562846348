const mag = {
  state: {
    magId: null,
    memberId: null,
    platMagId: null,
    platTenantId: null,
    platMagName: null,
    handleLoading: false,
    magsStatus: false
  },
  mutations: {
    SET_MAGID: (state, magId) => {
      state.magId = magId
    },
    SET_MEMBERID: (state, memberId) => {
      state.memberId = memberId
    },
    SET_PLATMAGID: (state, platMagId) => {
      state.platMagId = platMagId
    },
    SET_PLATTENANTID: (state, platTenantId) => {
      state.platTenantId = platTenantId
    },
    SET_PLATMAGNAME: (state, platMagName) => {
      state.platMagName = platMagName
    },
    SET_LOADING: (state, handleLoading) => {
      state.handleLoading = handleLoading
    },
    SET_MAGSSTATUS: (state, magsStatus) => {
      state.magsStatus = magsStatus
    },
    RESET_ALL: (state) => {
      state.magId = null
      state.memberId = null
      state.platMagId = null
      state.platTenantId = null
      state.platMagName = null
      state.handleLoading = false
      state.magsStatus = false
    }
  }
}

export default mag
