<template>
  <div>
    <div class="conter_page clearfix">
      <span class="">显示行数</span>
      <el-pagination
        :current-page="page"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="size"
        :total="total"
        class="conter_pages"
        :background="!noBackground"
        layout="sizes, prev, pager, next"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
      <div class="jumpPage">
        <span>前往</span>
        <input v-model="jumpPageNum" class="jumpPageInput" @keyup.enter="submit" @blur.prevent="submit" />
        <span>页</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Pagination',
  props: {
    initObj: {
      type: Object,
      default: () => {
        return {}
      }
    },
    noBackground: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      size: 10,
      page: 1,
      total: 0,
      jumpPageNum: '1'
    }
  },
  mounted() {
    if (this.initObj) {
      const { size = 10, page = 1, total = 0 } = this.initObj
      this.size = size
      this.page = page
      this.total = total
    }
  },
  watch: {
    initObj(nv) {
      console.log(nv)
      const { size = 10, page = 1, total = 0 } = nv
      this.size = size
      this.page = page
      this.total = total
    },
    jumpPageNum: function(newVal, oldVal) {
      if (newVal === '' || newVal === 1) {
        return
      }
      let myReg = /^[0-9]*$/
      if (myReg.test(newVal)) {
        let data = this.total % this.pageSize === 0 ? parseInt(this.total / this.size) : parseInt(this.total / this.size) + 1 // 最大值
        if (newVal > 0 && newVal <= data) {
          return
        }
      }
      this.jumpPageNum = oldVal // 恢复原值
    }
  },
  methods: {
    submit() {
      if (this.jumpPageNum == '') {
        this.jumpPageNum = '1'
      }
      this.handleCurrentChange(JSON.parse(this.jumpPageNum))
    },
    // 页容量改变
    handleSizeChange(currentsize) {
      this.$emit('onChangePageData', {
        page: this.page,
        size: currentsize
      })
      this.size = currentsize
    },
    // 页码改变
    handleCurrentChange(currentSelect) {
      this.$emit('onChangePageData', {
        page: currentSelect,
        size: this.size
      })
      this.page = currentSelect
    }
  }
}
</script>
<style scoped lang="scss">
.con_01 {
  width: 376px;
  height: 110px;
  background: #f5f7fd;
  margin-bottom: 5px;
}

.conter_1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 15px 0 25px;
}

.con_01_title {
  color: #666;
  font-size: 14px;
  text-align: center;
}

.conter_3 {
  padding: 15px 0 25px;
}

.tj_year {
  color: #999;
  font-size: 14px;
  position: relative;
  top: -3px;
  right: -10px;
}
.postion {
  position: absolute;
  top: 40px;
  left: 30px;
  z-index: 100;
}
.postion1 {
  position: absolute;
  top: 40px;
  right: 30px;
  z-index: 100;
}
.tj_year1 {
  color: #999;
  font-size: 14px;
  position: relative;
  top: -3px;
  right: -10px;
}
.linyu {
  display: flex;
}
.linyu ul li {
  line-height: 34px;
}
.linyu ul li a {
  color: #fff;
  font-size: 14px;
}
.ul1 {
  width: 242px;
}
.ul2 {
  width: 310px;
}
.ul3 {
  width: 262px;
}
.ul4 {
  flex: 1;
}
.centlin1 {
  position: absolute;
  top: 32px;
  right: 30px;
  z-index: 200;
}
.posion {
  position: relative;
}
.zk {
  position: absolute;
  top: 10px;
  right: 20px;
  color: #fff;
}
.linyugd {
  display: flex;
  flex-wrap: wrap;
}
.linyugd li {
  width: 25%;
  line-height: 34px;
  text-align: left;
}
.linyugd li a {
  color: #fff;
  font-size: 14px;
}
.jumpPage {
  float: left;
  padding: 3px 5px;
  margin-left: 24px;
  font-weight: 400;
  color: #606266;
}
.jumpPageInput {
  line-height: 18px;
  padding: 0 2px;
  height: 28px;
  text-align: center;
  margin: 0 2px;
  box-sizing: border-box;
  border-radius: 3px;
  color: #454647;
  width: 46px;
  border: 1px solid #dcdfe6;
}
.jumpPage .el-input__inner:focus {
  border-color: #409eff;
  outline: 0;
}
</style>
