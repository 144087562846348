<template>
  <div id="app">
    <template v-if="header_show">
      <Header />
    </template>
    <router-view />
  </div>
</template>
<script>
import Header from '@/components/Header'
export default {
  components: { Header },
  watch: {
    $route (to, from) {
      this.header_show = this.$route.path === '/' || ['emailPreviewHtml', 'citationPushPreviewHtml'].includes(this.$route.path) ? false : true
      this.specialPushResults = this.$route.path === '/specialPushResults' ? false : true
    }
  },

  data () {
    return {
      header_show: false,
      specialPushResults: true
    }
  }
}
</script>

<style scoped lang="scss">
#app {
  width: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
