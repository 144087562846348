import request from './services/axios.js'

// 引用列表
export function GetReference(params) {
  return request({
    params,
    url: '/vas/statistics/reference',
    method: 'get'
  })
}
// 引证列表
export function GetCitation(params) {
  return request({
    params,
    url: '/vas/statistics/citation',
    method: 'get'
  })
}
// 影响因子列表
export function GetInfluence(params) {
  return request({
    params,
    url: '/vas/statistics/influence',
    method: 'get'
  })
}
// 机构发文统计列表
export function GetInstitution(params) {
  return request({
    params,
    url: '/vas/statistics/institution',
    method: 'get'
  })
}
// 论文引证统计列表
export function GetArticle(params) {
  return request({
    params,
    url: '/vas/statistics/article',
    method: 'get'
  })
}
// 期刊领域概况
export function Statistics(params) {
  return request({
    params,
    url: '/vas/statistics',
    method: 'get'
  })
}
// 引证图谱中的引证列表查询
export function GetCitationGraph(params) {
  return request({
    params,
    url: '/vas/statistics/citationGraph',
    method: 'get'
  })
}
// 引证图谱中的引用列表查询
export function GetQuoteGraph(params) {
  return request({
    params,
    url: '/vas/statistics/referenceGraph',
    method: 'get'
  })
}
// 引证图谱中的总计引证和引用的大球中数据
export function GetAllCitationQuote(params) {
  return request({
    params,
    url: '/vas/mag/get',
    method: 'get'
  })
}
// 领域分析-领域期刊列表
export function GetDomainMags(params) {
  return request({
    params,
    url: '/vas/statistics/getDomainMags',
    method: 'get'
  })
}
// 获取期刊元数据-根据名称
export function GetMagByName(params) {
  return request({
    params,
    url: '/vas/mag/getMagByName',
    method: 'get'
  })
}
// 引证机构统计
export function CitationInstitution(params) {
  return request({
    params,
    url: '/vas/statistics/citationInstitution',
    method: 'get'
  })
}
// 期刊领域引证统计导出
export const exportCitation = PROJECT_CONFIG.BASE_URL + '/vas/statistics/exportCitation'
// 期刊领域引用统计导出
export const exportReference = PROJECT_CONFIG.BASE_URL + '/vas/statistics/exportReference'
// 期刊影响因子统计导出
export const exportInfluence = PROJECT_CONFIG.BASE_URL + '/vas/statistics/exportInfluence'
// 期刊发文机构统计导出
export const exportInstitution = PROJECT_CONFIG.BASE_URL + '/vas/statistics/exportInstitution'
// 期刊论文引证统计导出
export const exportArticle = PROJECT_CONFIG.BASE_URL + '/vas/statistics/exportArticle'
