const store = {
  magId: (state) => state.mag.magId,
  memberId: (state) => state.mag.memberId,
  platMagId: (state) => state.mag.platMagId,
  platTenantId: (state) => state.mag.platTenantId,
  platMagName: (state) => state.mag.platMagName,
  handleLoading: (state) => state.mag.handleLoading,
  magsStatus: (state) => state.mag.magsStatus
}
export default store
