<template>
  <div class="tooltip">
    <el-tooltip effect="dark" placement="top">
      <div slot="content">
        <!-- 插槽，可提供多行的提示信息 -->
        <img v-if="isTooltipImg" :src="tooltipImg" alt="" />
        <p v-else v-for="item in messages" :key="item">{{ item }}</p>
      </div>
      <i v-if="isIcon" class="el-icon-question icon-sty" />
      <span class="text-sty" v-else>{{ text }}</span>
    </el-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    isTooltipImg: {
      type: Boolean,
      default: false
    },
    tooltipImg: {
      type: String,
      default: ''
    },
    messages: {
      type: Array,
      default: () => {
        return []
      }
    },
    isIcon: {
      type: Boolean,
      default: true
    },
    text: {
      type: String,
      default: ''
    }
  }
}
</script>
<style lang="scss" scoped>
.tooltip {
  .icon-sty {
    color: #333333;
    position: absolute;
    top: 16px;
    cursor: pointer;
    margin-left: -10px;
  }
  .text-sty {
    font-size: 6px;
    color: #2644ca;
    cursor: pointer;
    margin-left: 10px;
  }
}
</style>
