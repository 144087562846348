const actions = {
  setMagId: ({ commit }, magId) => {
    return commit('SET_MAGID', magId)
  },
  setMemberId: ({ commit }, memberId) => {
    return commit('SET_MEMBERID', memberId)
  },
  setPlatMagId: ({ commit }, platMagId) => {
    return commit('SET_PLATMAGID', platMagId)
  },
  setPlatTenantId: ({ commit }, platTenantId) => {
    return commit('SET_PLATTENANTID', platTenantId)
  },
  setPlatMagName: ({ commit }, platMagName) => {
    return commit('SET_PLATMAGNAME', platMagName)
  },
  setHandleLoading: ({ commit }, handleLoading) => {
    return commit('SET_LOADING', handleLoading)
  },
  resetAll: ({ commit }) => {
    return commit('RESET_ALL')
  },
  setMagsStatus: ({ commit }, magsStatus) => {
    return commit('SET_MAGSSTATUS', magsStatus)
  }
}
export default actions
