import axios from 'axios'
import { Message, MessageBox } from 'element-ui'
import router from '../../router'

// 在main.js设置全局的请求次数，请求的间隙
axios.defaults.retry = 4
axios.defaults.retryDelay = 1000
axios.interceptors.response.use(undefined, (err) => {
  const { config } = err
  // 如果配置不存在或未设置重试选项，则拒绝
  if (!config || !config.retry) return Promise.reject(err)
  // 设置变量以跟踪重试次数
  config.__retryCount = config.__retryCount || 0
  // 检查我们是否已经注销了总重试次数
  if (config.__retryCount >= config.retry) {
    // 剔除错误
    return Promise.reject(err)
  }
  // 增加重试次数
  config.__retryCount += 1
  // 创建新的promise来处理指数退避
  const backoff = new Promise((resolve) => {
    setTimeout(() => {
      resolve()
    }, config.retryDelay || 1)
  })
  // 返回调用AXIOS来重试请求的promise
  return backoff.then(() => axios(config))
})
let handleUrl = ''
if (typeof PROJECT_CONFIG !== 'undefined' && typeof PROJECT_CONFIG.BASE_URL !== 'undefined') {
  if (PROJECT_CONFIG.BASE_URL.substring(0, 7) === 'http://') {
    handleUrl = document.location.protocol + '//' +PROJECT_CONFIG.BASE_URL.replace('http://', '')
  } else if (PROJECT_CONFIG.BASE_URL.substring(0, 8) === 'https://') {
    handleUrl = document.location.protocol + '//' +PROJECT_CONFIG.BASE_URL.replace('https://', '')
  }
}
const _axios = axios.create({
  baseURL: handleUrl // api的base_url
})

// request interceptor
_axios.interceptors.request.use(
  (config) => {
    if (sessionStorage.getItem('token')) {
      config.headers.token = sessionStorage.getItem('token')
    }
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

// respone interceptor
_axios.interceptors.response.use(
  (response) => {
    let data = response.data
    if (data.status === -2) {
      Message({
        message: `${data.message}`,
        type: 'error',
        onClose() {
          sessionStorage.setItem('token', '')
          router.push({
            path: '/'
          })
        }
      })
    }
    return response
  },
  (error) => {
    Message({
      message: `${error.response.data.message}`,
      type: 'error'
    })
    return Promise.reject(error)
  }
)

export default _axios
